<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <h2 class="brand-text text-primary ml-1">Utilis</h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t("modules.auth.forgotPassword") }} 🔒
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t("modules.auth.enterYourEmail") }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- email -->
                        <b-form-group
                            label="Email"
                            labelFor="forgot-password-email"
                        >
                            <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="forgot-password-email"
                                    v-model="userEmail"
                                    :state="errors.length > 0 ? false : null"
                                    name="forgot-password-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-button variant="primary" block type="submit">
                            {{ $t("modules.auth.sendResetLink") }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'auth-login-v1' }">
                        <feather-icon icon="ChevronLeftIcon" />
                        {{ $t("modules.auth.backToLogin") }}
                    </b-link>
                </b-card-text>
            </b-card>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"

import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
} from "bootstrap-vue"
import { required, email } from "@validations"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: "",
            // validation
            required,
            email,
        }
    },
    methods: {
        validationForm() {
            try {
                this.$refs.simpleRules.validate().then(async success => {
                    if (success) {
                        await this.$http.get("/sanctum/csrf-cookie")

                        await this.$http.post("/password/email", {
                            email: this.userEmail,
                        })
                        this.$toast(toastMessages.getSuccessMessage())
                    }
                })
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
